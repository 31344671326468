/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-segmentation-color-seed-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.neuroglancer-segmentation-color-seed-control input {
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  border: 0px;
}
