/**
 * @license
 * Copyright 2021 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-tool-key-binding {
  display: inline-block;
  color: #0ff;
  font: 9pt monospace;
  white-space: pre;
}

.neuroglancer-tool-key-binding:hover {
  outline: 1px solid #fff;
}

.neuroglancer-tool-key-binding::before {
  content: "[";
}

.neuroglancer-tool-key-binding::after {
  content: "]";
}

.neuroglancer-tool-key-binding::before,
.neuroglancer-tool-key-binding::after {
  color: #999;
}

.neuroglancer-tool-button {
  display: inline-flex;
  flex-direction: row;
}

#statusContainer li.neuroglancer-tool-status {
  max-height: 50vh;
}

.neuroglancer-tool-status-bindings {
  background-color: #333;
  user-select: none;
}

.neuroglancer-tool-status-content {
  display: flex;
  flex-direction: row;
}

.neuroglancer-tool-status-header-container {
  background-color: #555;
  width: min-content;
  padding: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.neuroglancer-tool-status-header {
  align-self: center;
}

.neuroglancer-tool-status-body {
  flex: 1;
  padding: 2px;
}
