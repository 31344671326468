/**
 * @license
 * Copyright 2019 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-statistics-panel-body {
  flex: 1;
  flex-basis: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font: 10pt sans-serif;
}

.neuroglancer-statistics-panel-body > table {
  position: relative;
  flex: 1;
  width: 100%;
}

.neuroglancer-statistics-panel-body > table > thead td {
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #333;
  font-weight: bold;
}

.neuroglancer-statistics-panel-body > table > tbody tr:hover {
  background-color: #336;
}
