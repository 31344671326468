/**
 * @license
 * Copyright 2021 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-layer-options-control-container
  .neuroglancer-layer-control-label-container {
  margin-right: auto;
}

.neuroglancer-layer-control-label-container {
  white-space: nowrap;
}

.neuroglancer-layer-control-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.neuroglancer-layer-control-label-text-container {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.neuroglancer-layer-control-container {
  margin-top: 1.5px;
  margin-bottom: 1.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.neuroglancer-layer-control-control {
  flex: 1;
  margin-left: 5px;
  max-width: 500px;
}

select.neuroglancer-layer-control-control,
input[type="checkbox"].neuroglancer-layer-control-control,
input[type="color"].neuroglancer-layer-control-control {
  flex: initial;
}
