/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-help-body {
  flex-basis: 0px;
  flex: 1;
  height: 0px;
  min-height: 0px;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
}

.neuroglancer-help-scroll-container {
  flex: 1;
  flex-basis: 0px;
  position: relative;
  overflow: auto;
}

.neuroglancer-help-scroll-container div + h2 {
  margin-top: 1em;
}

.neuroglancer-help-scroll-container h2 {
  font-size: 10pt;
  padding: 4px;
  position: sticky;
  top: 0;
  background-color: #333;
  margin-top: 0px;
}

.neuroglancer-help-scroll-container .dt {
  font-family: monospace;
  font-weight: bold;
  color: yellow;
}

.neuroglancer-help-scroll-container .dd {
  font-size: 10pt;
  margin-left: 4ex;
}

.neuroglancer-build-info {
  font-size: 10pt;
  user-select: text;
}

.neuroglancer-build-info a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.neuroglancer-build-info a:hover {
  background-color: #db4437;
}
