/**
 * @license
 * Copyright 2018 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-annotation-tool-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  scrollbar-width: none;
}

.neuroglancer-annotation-tool-status::-webkit-scrollbar {
  display: none;
}

.neuroglancer-annotation-tool-status-widget
  + .neuroglancer-annotation-tool-status-widget {
  margin-left: 3px;
}

.neuroglancer-annotation-tool-status-widget:hover {
  outline: 1px solid #fff;
}

.neuroglancer-annotation-tool-status-widget {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.neuroglancer-annotation-tool-status-widget-layer-number {
  display: inline-block;
  font: 10pt sans-serif;
  font-weight: bold;
  background-color: #9a7518;
}

.neuroglancer-annotation-tool-status-widget-key {
  display: inline-block;
  font: 9pt monospace;
  color: #0ff;
}

.neuroglancer-annotation-tool-status-widget-key::before {
  content: "[";
}

.neuroglancer-annotation-tool-status-widget-key::after {
  content: "]";
}

.neuroglancer-annotation-tool-status-widget-key::before,
.neuroglancer-annotation-tool-status-widget-key::after {
  color: #999;
}

.neuroglancer-annotation-tool-status-widget-description {
  display: inline-block;
  color: #3c3;
  font: 10pt sans-serif;
  white-space: nowrap;
}

.neuroglancer-annotation-tool-status-widget-delete {
  visibility: hidden;
}

.neuroglancer-annotation-tool-status-widget:hover
  > .neuroglancer-annotation-tool-status-widget-delete {
  visibility: inherit;
}
