/**
 * @license
 * Copyright 2019 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-channel-dimensions-widget-dim {
  display: contents;
}

.neuroglancer-channel-dimensions-widget {
  display: grid;
  grid-template-columns: [name] min-content [lower] min-content [upper] min-content;
}

.neuroglancer-channel-dimensions-widget-name-container {
  grid-column: name;
}

.neuroglancer-channel-dimensions-widget-name[data-is-valid="false"],
.neuroglancer-channel-dimensions-widget-name::placeholder {
  text-decoration: solid underline red;
}

.neuroglancer-channel-dimensions-widget-name {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  border: 0;
  margin: 0;
  font-family: monospace;
  font-size: medium;
  color: #ff6;
}

.neuroglancer-channel-dimensions-widget-name,
.neuroglancer-channel-dimensions-widget-lower,
.neuroglancer-channel-dimensions-widget-upper {
  font-family: monospace;
}

.neuroglancer-channel-dimensions-widget-lower {
  grid-column: lower;
  text-align: right;
}

.neuroglancer-channel-dimensions-widget-upper {
  grid-column: upper;
  text-align: right;
}

.neuroglancer-channel-dimensions-widget-lower,
.neuroglancer-channel-dimensions-widget-upper {
  text-align: right;
  margin-left: 1ch;
  user-select: text;
}

.neuroglancer-channel-dimensions-widget-upper::after {
  content: ")";
}

.neuroglancer-channel-dimensions-widget-lower::after {
  content: ",";
}

.neuroglancer-channel-dimensions-widget-lower::before {
  content: "[";
}

.neuroglancer-channel-dimensions-widget-upper::after,
.neuroglancer-channel-dimensions-widget-lower::before,
.neuroglancer-channel-dimensions-widget-lower::after {
  color: #999;
}
