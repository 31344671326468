/**
 * @license
 * Copyright 2017 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-position-dimension-plot {
  display: grid;
  overflow: hidden;
  position: relative;
}

.neuroglancer-position-dimension-plot[data-orientation="column"] {
  grid-template-areas: "labels . graph";
  grid-template-rows: 1fr;
  grid-template-columns: 0fr 3px 0fr;
}

.neuroglancer-position-dimension-plot[data-orientation="row"] {
  grid-template-areas:
    "labels"
    "."
    "graph";
  grid-template-columns: minmax(0px, 1fr);
  grid-template-rows: 0fr 3px 0fr;
}

.neuroglancer-position-dimension-plot-lowerbound,
.neuroglancer-position-dimension-plot-upperbound,
.neuroglancer-position-dimension-plot-hoverposition {
  grid-area: labels;
  font-family: monospace;
  font-size: medium;
  user-select: none;
}

.neuroglancer-position-dimension-plot[data-orientation="column"]
  :is(
    .neuroglancer-position-dimension-plot-lowerbound,
    .neuroglancer-position-dimension-plot-upperbound,
    .neuroglancer-position-dimension-plot-hoverposition
  ) {
  text-align: right;
}

.neuroglancer-position-dimension-plot[data-orientation="row"]
  :is(
    .neuroglancer-position-dimension-plot-lowerbound,
    .neuroglancer-position-dimension-plot-upperbound,
    .neuroglancer-position-dimension-plot-hoverposition
  ) {
  max-width: max-content;
}

.neuroglancer-position-dimension-plot > canvas {
  grid-area: graph;
}

.neuroglancer-position-dimension-plot[data-orientation="column"] > canvas {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
