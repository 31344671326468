/**
 * @license
 * Copyright 2019 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-coordinate-space-transform-widget {
  display: grid;
  justify-items: stretch;
}

.neuroglancer-coordinate-space-transform-widget input {
  font-family: monospace;
  justify-self: stretch;
  background-color: black;
  color: white;
  padding: 2px;
}

.neuroglancer-coordinate-space-transform-widget *[data-will-be-deleted="true"] {
  color: #666;
}

.neuroglancer-coordinate-space-transform-label {
  font-style: italic;
  color: #fcc;
  align-self: center;
}

.neuroglancer-coordinate-space-transform-input-lower-label {
  grid-row: sourceLower;
  grid-column: outputLabel / sourceDim 1;
}

.neuroglancer-coordinate-space-transform-translation-label {
  grid-row: sourceLabel / outputDim 1;
  grid-column: sourceDim -1;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  align-self: end;
  justify-self: center;
  margin-bottom: 2px;
}

.neuroglancer-coordinate-space-transform-input-lower-label,
.neuroglancer-coordinate-space-transform-input-upper-label,
.neuroglancer-coordinate-space-transform-input-scale-label {
  text-align: right;
  margin-right: 4px;
}

.neuroglancer-coordinate-space-transform-input-upper-label {
  grid-row: sourceUpper;
  grid-column: headerStart / headerEnd;
}

.neuroglancer-coordinate-space-transform-input-scale-label {
  grid-row: 5;
  grid-column: headerStart / headerEnd;
}

.neuroglancer-coordinate-space-transform-source-label {
  grid-row: sourceLabel;
  grid-column: sourceDim 1 / sourceDim -1;
  text-align: center;
}

.neuroglancer-coordinate-space-transform-output-label {
  grid-row: outputDim 1 / outputDim -1;
  grid-column: outputLabel;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  align-self: center;
  justify-self: end;
  margin-right: 2px;
}

.neuroglancer-coordinate-space-transform-input-name {
  text-align: center;
}

.neuroglancer-coordinate-space-transform-input-name,
input.neuroglancer-coordinate-space-transform-output-name {
  font-family: monospace;
  color: #ff6;
}

.neuroglancer-coordinate-space-transform-scale {
  text-align: right;
}

.neuroglancer-coordinate-space-transform-input-bounds,
.neuroglancer-coordinate-space-transform-output-bounds {
  white-space: nowrap;
  font-family: monospace;
  font-size: small;
  text-align: right;
  padding-left: 2px;
  padding-right: 2px;
  color: #aff;
}

.neuroglancer-coordinate-space-transform-input-bounds.neuroglancer-coordinate-space-transform-singleton:not(
    *[data-will-be-deleted="true"]
  ) {
  color: #fa6;
}

.neuroglancer-coordinate-space-transform-widget input[data-is-valid="false"],
.neuroglancer-coordinate-space-transform-widget
  input[data-is-valid="false"]::placeholder {
  text-decoration: solid underline red;
}

.neuroglancer-coordinate-space-transform-output-extend {
  grid-row: outputDim -1;
  grid-column: outputNames;
  display: flex;
  flex-direction: column;
}

.neuroglancer-coordinate-space-transform-output-extend input {
  align-self: stretch;
}

.neuroglancer-coordinate-space-transform-output-extend[data-is-active="false"]
  input {
  display: none;
}

.neuroglancer-coordinate-space-transform-output-extend[data-is-active="true"]
  .neuroglancer-icon {
  display: none;
}

.neuroglancer-coordinate-space-transform-output-extend .neuroglancer-icon {
  align-self: start;
}

.neuroglancer-coordinate-space-transform-translation-coeff {
  margin-left: 3px;
}

.neuroglancer-coordinate-space-transform-output-scale-container {
  margin-right: 3px;
}

.neuroglancer-coordinate-space-transform-input-scale-container,
.neuroglancer-coordinate-space-transform-input-scale-label {
  margin-bottom: 3px;
}

.neuroglancer-coordinate-space-transform-widget input,
.neuroglancer-coordinate-space-transform-output-name-container,
.neuroglancer-coordinate-space-transform-scale-container {
  border: 1px solid #333;
}

.neuroglancer-coordinate-space-transform-widget
  .neuroglancer-coordinate-space-transform-output-name,
.neuroglancer-coordinate-space-transform-widget
  .neuroglancer-coordinate-space-transform-scale {
  border: 0px;
}

.neuroglancer-coordinate-space-transform-output-name-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-right: 3px;
}

.neuroglancer-coordinate-space-transform-scale-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.neuroglancer-coordinate-space-transform-scale-suggestion {
  font-family: monospace;
  cursor: pointer;
  border-radius: 20%;
  border: 1px solid #333;
  align-self: flex-end;
  margin: 2px;
  color: #aaa;
}

.neuroglancer-coordinate-space-transform-scale-suggestion svg {
  width: 16px;
  height: 16px;
  fill: transparent;
  stroke: #aaa;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.neuroglancer-coordinate-space-transform-scale-suggestion:hover {
  background-color: #db4437;
  border: 1px solid transparent;
  color: white;
}

.neuroglancer-coordinate-space-transform-scale-suggestion:hover svg {
  stroke: white;
}

.neuroglancer-coordinate-space-transform-widget-reset-buttons {
  grid-row: outputDim -1;
  grid-column: headerEnd / -1;
  justify-self: start;
}
