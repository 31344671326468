/**
 * @license
 * Copyright 2019 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-render-scale-widget {
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.neuroglancer-render-scale-widget-prompt {
  white-space: nowrap;
}

.neuroglancer-render-scale-widget > canvas {
  height: 36px;
  flex: 1;
  flex-basis: 0px;
  width: 0px;
}

.neuroglancer-render-scale-widget-legend {
  width: 15ch;
  font-size: 11px;
  text-align: right;
}

.neuroglancer-render-scale-widget-legend > div {
  height: 12px;
}
