/**
 * @license
 * Copyright 2018 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.neuroglancer-icon {
  display: inline-flex;
  text-decoration: none;
  align-self: center;
  white-space: nowrap;
  padding-left: 2px;
  padding-right: 2px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 20%;
  align-items: center;
  justify-content: center;
  color: white;
  font: 12px sans-serif;
  cursor: pointer;
  font-weight: 900;
  margin-left: 1px;
  margin-right: 1px;
  user-select: none;
}

.neuroglancer-icon svg {
  width: 16px;
  height: 16px;
  fill: transparent;
  stroke: white;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: none;
}

.neuroglancer-icon:hover {
  background-color: #db4437;
}

.neuroglancer-icon-hover:not(:hover) svg:last-child {
  display: none;
}

.neuroglancer-icon-hover:hover svg:first-child {
  display: none;
}
