.neuroglancer-side-panel-column {
  display: flex;
  flex-direction: column;
  min-width: 0px;
}

.neuroglancer-side-panel-row {
  display: flex;
  flex-direction: row;
  min-height: 0px;
}

.neuroglancer-side-panel {
  display: flex;
  flex-direction: column;
  min-width: 0px;
  overflow: hidden;
}

.neuroglancer-side-panel-titlebar {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: sans-serif;
  font-size: 10pt;
  background-color: #03c;
  padding: 2px;
  color: white;
}

.neuroglancer-side-panel-title {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.neuroglancer-side-panel-drop-zone {
  z-index: 1000;
  display: none;
}

[data-neuroglancer-side-panel-drag] .neuroglancer-side-panel-drop-zone {
  display: block;
}

.neuroglancer-resize-gutter-vertical {
  height: 1px;
  background-color: #333;
  background-clip: content-box;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: row-resize;
}

.neuroglancer-resize-gutter-horizontal {
  width: 1px;
  background-color: #333;
  background-clip: content-box;
  padding-right: 2px;
  padding-left: 2px;
  cursor: col-resize;
}
